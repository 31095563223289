import { QueryCache } from 'react-query';
import _ from 'lodash';

// Detect local development by checking for local.dev.manage.rackspace.com domain.
export const isLocal = () => /local\.dev\.manage\.rackspace\.com/i.test(global.location.origin);
export const isDev = () => /\/dev\.manage\.rackspace\.com/i.test(global.location.origin);
export const isProd = () => /\/manage\.rackspace\.com/i.test(global.location.origin);

export const env = () => _.get(_.find([
  [isLocal, 'local'],
  [isDev, 'dev'],
  [isProd, 'prod']
], ([criteria]) => criteria()), 1);

const globalAudience = () => global.isRacker ? 'racker' : 'customer';
const locationAudience = () => (/\/racker($|\/)/gi).test(`${global.location.pathname}`) ? 'racker' : 'customer';

export const audience = () => _.isBoolean(global.isRacker) ? globalAudience() : locationAudience();
export const isRacker = () => (audience() === 'racker');

// When running locally, use dev.manage.rackspace.com for the UI backend. Otherwise, use the domain
// of the website. This allows local development to piggy back off dev.manage.rackspace.com's backend.
export const baseUrl = () => (isLocal() ? 'https://dev.manage.rackspace.com' : global.location.origin);

const rackerNavRegex = /(.*\/racker\/[a-z:0-9]+\/?)([a-z:0-9]+|$)(.*|$)/i;

export const rackerNavConfigFn = (history) => {
  return {
    racker: true,
    domainFromURLRegex: rackerNavRegex,
    active: 'racker-dashboard',

    onCustomerContextChange: (e) => {
      e.preventDefault();
      const domainFromURL = rackerNavRegex.exec(window.location.pathname)?.[2] ?? '';
      const pilotDomain = e.pilot.getCustomer().domain;
      if (pilotDomain && domainFromURL !== pilotDomain) {
        history.push(`/racker/customers/${pilotDomain}`);
      }
    },
    changeLocation: () => {
      // Clearing the co-pilot context on search page
      if (/(.*\/racker\/search$)/.test(window.location.pathname)) {
        window.pilot.setCustomer();
      }
    },
    // Overwriting the method to prevent default redirection
    changeLocationRemote: _.noop
  };
};

export const componentMaturity = () => ({
  level: {
    deprecated: 'fatal',
    pre: 'none',
    initial: 'none',
    unstable: 'none',
    feedback: 'none',
    stable: 'none',
    proven: 'none'
  }
});


export const queryCache = () => {
  return new QueryCache({
    defaultConfig: {
      queries: {
        refetchIntervalInBackground: false,
        refetchInterval: false,
        refetchOnMount: true,
        staleTime: Infinity,
        refetchOnWindowFocus: false
      }
    }
  });
};

