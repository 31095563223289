import React from 'react';
import * as customer from 'customer/data.js';
import { useQuery, useMutation, queryCache } from 'react-query';

export const useQueryListCustomerAccountsForDomain = (domain) => {
  const query = useQuery(['listCustomerAccountsForDomain', domain], customer.listCustomerAccountsForDomain);
  return React.useMemo(() => ({ query }), [query]);
};

export const useQueryGetCustomerAccountDetails = ({ domain, type, id } = {}) => {
  const query = useQuery(['getCustomerAccountDetails', domain, type, id], customer.getCustomerAccountDetails);
  return React.useMemo(() => ({ query }), [query]);
};

export const useQueryGetCustomerAccountMetadata = ({ domain, type, id } = {}) => {
  const query = useQuery(['getCustomerAccountMetadata', domain, type, id], customer.getCustomerAccountMetadata);
  return React.useMemo(() => ({ query }), [query]);
};

export const useQueryGetCustomerInformation = (domain) => {
  const query = useQuery(['getCustomerInformation', domain], customer.getCustomerInformation);
  return React.useMemo(() => ({ query }), [query]);
};

export const useQueryGetGCPAccessGrants = () => {
  const query = useQuery(['getGCPAccessGrants'], customer.getGCPAccessGrants);
  return React.useMemo(() => ({ query }), [query]);
};

export const useMutationSetGCPAccessGrant = () => {
  const [mutate, result] = useMutation(
    (projectId) => customer.setGCPAccessGrants(projectId), {
      onSuccess: () => {
        queryCache.invalidateQueries('getGCPAccessGrants');
      }
    }
  );

  return [mutate, result.isLoading];
};
