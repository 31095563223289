import _ from 'lodash';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { ApplicationContainer, context } from '@janus.team/janus-copilot/cjs/janus-copilot.js';
import { configuration } from '@janus.team/janus-particles';
import { useIsFetching } from 'react-query';
import { Helmet } from 'react-helmet';

import { copilotConfiguration } from 'config/copilot.js';
import { audience, componentMaturity, rackerNavConfigFn } from 'config/index.js';
import { CustomerAccountView, RackerAccountView } from 'customer/index.js';
import CustomerSearch from 'search/customer-search.js';
import * as b from 'react-bootstrap';

const useInstrumentRUMWithUser = () => {
  const { session } = React.useContext(context.session);
  datadogRum.addRumGlobalContext('usr', {
    id: session.user.id,
    name: session.user.name,
    type: audience()
  });
};

const LoadingIndicator = () => {
  const isFetching = useIsFetching();
  return isFetching ? (
    <div className="rsd-global-loading-indicator">
      <b.Spinner animation="border" variant="dark" size={'sm'} />
    </div>
  ) : null;
};

const RackerAppReady = () => {
  useInstrumentRUMWithUser();

  return (
    <>
      <Switch>
        <Route path="/racker/search"><CustomerSearch /></Route>
        <Route path="/racker/customers/:domain"><RackerAccountView /></Route>
        <Redirect to="/racker/search" />
      </Switch>
      <LoadingIndicator />
    </>
  );
};

const RackerApp = () => {
  const history = useHistory();
  return _.set(global, 'isRacker', true) && (
    <div>
      <ApplicationContainer {...copilotConfiguration(rackerNavConfigFn(history))}>
        <ApplicationContainer.Authenticating>
          <ApplicationContainer.LoadingIndicator />
        </ApplicationContainer.Authenticating>
        <ApplicationContainer.Ready>
          <RackerAppReady />
        </ApplicationContainer.Ready>
      </ApplicationContainer>
    </div>
  );
};

const CustomerAppReady = () => {
  useInstrumentRUMWithUser();

  return (
    <>
      <Switch>
        <Route path="/"><CustomerAccountView /></Route>
      </Switch>
      <LoadingIndicator />
    </>
  );
};

const CustomerApp = () => _.set(global, 'isRacker', false) && (
  <ApplicationContainer {...copilotConfiguration({ racker: false, navSection: 'my-accounts' })}>
    <ApplicationContainer.Authenticating>
      <ApplicationContainer.LoadingIndicator />
    </ApplicationContainer.Authenticating>
    <ApplicationContainer.Ready>
      <CustomerAppReady />
    </ApplicationContainer.Ready>
  </ApplicationContainer>
);

export const App = () => {
  return (
    <configuration.ConfigurationProvider componentMaturity={componentMaturity()}>
      <Helmet>
        <title>Accounts | Rackspace</title>
      </Helmet>
      <Switch>
        <Route path="/racker"><RackerApp /></Route>
        <Route path="/r"><RackerApp /></Route>
        <Route path="/"><CustomerApp /></Route>
      </Switch>
    </configuration.ConfigurationProvider>
  );
};

export const AppWithRouter = () => (<Router basename={process.env.PUBLIC_URL}><App /></Router>);

export default App;
