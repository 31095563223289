import React, { useEffect } from 'react';
import { useQueryGetCustomerInformation } from 'customer/queries.js';
import { Switch, Route, useParams } from 'react-router-dom';
import AccountList from 'customer/account-list.js';
import { Helmet } from 'react-helmet';
import { Page } from '@janus.team/janus-particles';
import { t } from 'translations/index.js';
import { isProd } from '../config/index';

export const context = React.createContext({});

export const isOptionDisabled = (loginType, accountType, roles) => {
  if (loginType === 'racker') {
    if (accountType.name === 'AZURE') {
      return !roles?.some((role) => {
        return role.name === 'rax-aws-saml-janus-prod-admins'
          || role.name === 'janus-azure-product-architecture'
          || role.name === 'janus-azure-service-admins'
          || role.name === 'rss-platform-eng';
      });
    } else if (accountType.name === 'GCP') {
      return !roles?.some((role) => {
        return role.name === 'mgcp-support'
          || role.name === 'MPC-TOMS'
          || role.name === 'rax-aws-saml-janus-prod-admins';
      });
    }
    // any racker can access VMWARE
    return false;
  }

  if (accountType.name === 'AWS') {
    return !roles?.some((role) => {
      return role.name === 'identity:user-admin'
        || role.name === 'aws:account-creator';
    });
  } else if (accountType.name === 'AZURE') {
    return !roles?.some((role) => {
      return role.name === 'identity:user-admin'
        || role.name === 'azure:account-creator'
        || role.name === 'identity:user-manage';
    });
  }
  // for customer GCP
  return false;
};

export const getURL = (accountType, domainId) => {
  if (isProd()) {
    return accountType.link.replace('DOMAIN_ID', domainId);
  }
  return accountType.link.replace('DOMAIN_ID', domainId)
    .replace('manage.rackspace.com', 'dev.manage.rackspace.com');
};

export const RackerAccountView = () => {
  const domain = useParams()?.domain;
  const customerInformationQuery = useQueryGetCustomerInformation(domain);
  const className = customerInformationQuery?.query?.isLoading ? 'rsd-skeleton__placeholder rsd-skeleton' : '';
  const name = customerInformationQuery?.query?.data?.name || t('Customer');
  const category = <span>{domain}</span>;
  const title = <span {...{ className }}>{name}</span>;
  useEffect(()=>{
    if(window.pilot){
      // Updating the co-pilot context for navigation
      // though react-router
      window.pilot.setCustomer(name, domain);
    }
  },[name, domain]);

  return (
    <context.Provider value={customerInformationQuery?.query?.data}>
      <Helmet>
        <title>{name} | {domain} | {t('Account List')} | {t('Rackspace')}</title>
      </Helmet>
      <Page.Main>
        <Page.MainHeader {...{ title, category }} withBackground />
        <Page.MainBody>
          <Switch>
            <Route path="/racker/customers/:domain">
              <AccountList />
            </Route>
          </Switch>
        </Page.MainBody>
      </Page.Main>
    </context.Provider>
  );
};

export const CustomerAccountView = () => {
  const title = t('Accounts');
  return (
    <>
      <Helmet>
        <title>{title} | {t('Rackspace')}</title>
      </Helmet>
      <Page.Main>
        <Page.MainHeader title={title} withSections/>
        <Page.MainBody>
          <AccountList />
        </Page.MainBody>
      </Page.Main>
    </>
  );
};
